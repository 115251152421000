import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Button from "gatsby-theme-rothenberger/src/@yumgmbh/gatsby-theme-yum-components/components/atoms/Button"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import {
  Card,
  CardHead,
  CardBody,
} from "gatsby-theme-rothenberger/src/components/molecules/Card"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/sharp-light-svg-icons"

const DemoTest = () => {
  return (
    <Layout>
      <BlockWrapper block={{ headline: "Cards" }} showHeadline="true">
        <Card type="white" className="block w-1/3" link="https://example.com">
          <div className="flex-row md:flex">
            <CardHead className="md:w-1/2">
              <Image
                image={{
                  alt: "ROMAX COMPACT III AMPShare",
                  filename:
                    "https://a.storyblok.com/f/125728/1245x3065/a45f1e0311/1000004266_romax_compact_iii_amp_p02_2.jpg",
                }}
                aspectRatio="1by1"
                className="hidden md:block w-full aspect-[1/1]"
              />
              <Image
                image={{
                  alt: "ROMAX COMPACT III AMPShare",
                  filename:
                    "https://a.storyblok.com/f/125728/1245x3065/a45f1e0311/1000004266_romax_compact_iii_amp_p02_2.jpg",
                }}
                aspectRatio="4by3"
                className="block md:hidden w-full aspect-[4/3]"
              />
            </CardHead>
            <CardBody type="bigPadded" className="md:w-1/2">
              <div className="flex flex-col justify-between h-full">
                <div>Testtext</div>
                <div
                  href="#"
                  className="text-ro-red group-hover:text-ro-red-700"
                >
                  Pseudolink{" "}
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    size="1x"
                    className="inline-block self-center ml-auto md:ml-2 w-[1.125rem] h-[1.125rem]"
                  />
                </div>
              </div>
            </CardBody>
          </div>
        </Card>
      </BlockWrapper>

      <BlockWrapper block={{}}>
        <Card
          type="gray"
          className="block w-40 h-40"
          link="https://example.com"
        >
          Card gray
        </Card>
      </BlockWrapper>

      <BlockWrapper block={{ headline: "Buttons" }} showHeadline="true">
        <Button btnType="primary" link="https://example.com" target="_blank">
          Button primary
        </Button>
      </BlockWrapper>
      <BlockWrapper block={{}}>
        <Button
          btnType="primary"
          btnState="disabled"
          link="https://example.com"
          target="_blank"
        >
          Button primary disabled
        </Button>
      </BlockWrapper>
      <BlockWrapper block={{}}>
        <Button btnType="secondary" link="https://example.com" target="_blank">
          Button secondary
        </Button>
      </BlockWrapper>
      <BlockWrapper block={{}}>
        <Button
          btnType="secondary"
          btnState="disabled"
          link="https://example.com"
          target="_blank"
        >
          Button secondary disabled
        </Button>
      </BlockWrapper>
      <BlockWrapper block={{}}>
        <Button btnType="outlineRed" link="https://example.com" target="_blank">
          Button outlineRed
        </Button>
      </BlockWrapper>
      <BlockWrapper block={{}}>
        <Button
          btnType="outlineRed"
          btnState="disabled"
          link="https://example.com"
          target="_blank"
        >
          Button outlineRed disabled
        </Button>
      </BlockWrapper>
      <BlockWrapper block={{}} className="bg-ro-gray-900">
        <Button
          btnType="outlineWhite"
          link="https://example.com"
          target="_blank"
        >
          Button outlineWhite
        </Button>
      </BlockWrapper>
      <BlockWrapper block={{}} className="bg-ro-gray-900">
        <Button
          btnType="outlineWhite"
          btnState="disabled"
          link="https://example.com"
          target="_blank"
        >
          Button outlineWhite disabled
        </Button>
      </BlockWrapper>
    </Layout>
  )
}

export default DemoTest
